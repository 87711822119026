import * as React from "react"
import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react"
import Layout from "../components/Layout"
import ShepherdsContainer from "../components/ShepherdsContainer"
import corky from "../images/corky.jpg"

const About = () => {
  const [isMobile] = useMediaQuery("(max-width: 550px)")

  if (isMobile)
    return (
      <Layout>
        <ShepherdsContainer>
          <Flex direction="column" align="center">
            <Heading mt={8}>About us</Heading>
            <Heading size="lg">"Feed My Sheep"</Heading>
            <Image my="4" src={corky} htmlHeight="300" htmlWidth="232" />
            <Heading mb="5" size="md">
              Matthew 25:40 "Truly I tell you, whatever you did for one of the
              least of these brothers and sisters of mine, you did for me."
            </Heading>
            <Text mb="5">
              Corky Arthur, a member of Tuskawilla United Methodist Church, in
              Casselberry, FL, saw a need in 1993 to provide food for people who
              were desperately in need of help.
            </Text>
            <Text mb="5">
              He started, with his family, to prepare food and go to various
              places that he discovered the homeless gathered. Corky discovered
              quickly that he would need financial assistance to continue his
              ministry. He formed Tuskawilla Shepherds, Inc. a non-profit
              501(c)3 and started to solicit donations from the business
              community, private individuals, civic clubs, etc.
            </Text>
            <Text mb="5">
              When Corky passed away , his family took over and kept this vital
              service and ministry going. His grandson-in-law, Chris Lilly, is
              president of Tuskawilla Shepherds and all children, grandchildren,
              nieces, nephews, sons and daughters in law are actively involved
              in this ministry.
            </Text>
            <Text>
              Tuskawilla Shepherds has served over 380,000 meals to homeless and
              disadvantaged people–and are now focusing on the children.
            </Text>
          </Flex>
        </ShepherdsContainer>
      </Layout>
    )

  return (
    <Layout>
      <ShepherdsContainer>
        <Heading mt={8}>About us</Heading>
        <Heading size="lg">"Feed My Sheep"</Heading>
        <Box>
          <Image src={corky} htmlHeight="300" htmlWidth="232" float="left" />
          <Heading mb="5" size="md">
            Matthew 25:40 "Truly I tell you, whatever you did for one of the
            least of these brothers and sisters of mine, you did for me."
          </Heading>
          <Text mb="5">
            Corky Arthur, a member of Tuskawilla United Methodist Church, in
            Casselberry, FL, saw a need in 1993 to provide food for people who
            were desperately in need of help.
          </Text>
          <Text mb="5">
            He started, with his family, to prepare food and go to various
            places that he discovered the homeless gathered. Corky discovered
            quickly that he would need financial assistance to continue his
            ministry. He formed Tuskawilla Shepherds, Inc. a non-profit 501(c)3
            and started to solicit donations from the business community,
            private individuals, civic clubs, etc.
          </Text>
          <Text mb="5">
            When Corky passed away , his family took over and kept this vital
            service and ministry going. His grandson-in-law, Chris Lilly, is
            president of Tuskawilla Shepherds and all children, grandchildren,
            nieces, nephews, sons and daughters in law are actively involved in
            this ministry.
          </Text>
          <Text>
            Tuskawilla Shepherds has served over 380,000 meals to homeless and
            disadvantaged people–and are now focusing on the children.
          </Text>
        </Box>
      </ShepherdsContainer>
    </Layout>
  )
}

export default About

export const Head = () => <title>About Tuskawilla Shepherds</title>
